import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, useHistory } from 'react-router-dom'
export function PrivateRoute({ component: Component, permission, ...rest }) {
  // const token = localStorage.getItem('access_token')
    const token = true
  const history = useHistory()
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component history={history} {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
PrivateRoute.propTypes = {
  component: PropTypes.any,
  permission: PropTypes.bool,
  location: PropTypes.any,
}
