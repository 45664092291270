import {
  VERIFY_PIN,
  VERIFY_PIN_RESPONSE,
  SAVE_REVIEW,
  SAVE_REVIEW_RESPONSE,
  GET_IP,
  GET_IP_RESPONSE,
} from './login.types'

export const checkPin = (pinData) => ({
  type: VERIFY_PIN,
  payload: pinData,
})

export const checkPinResponse = (pinResponse) => ({
  type: VERIFY_PIN_RESPONSE,
  payload: pinResponse,
})

export const saveReview = (pinData) => ({
  type: SAVE_REVIEW,
  payload: pinData,
})

export const saveReviewResponse = (pinResponse) => ({
  type: SAVE_REVIEW_RESPONSE,
  payload: pinResponse,
})

export const getIp = (data) => ({
  type: GET_IP,
  payload: data,
})

export const getIpResponse = (response) => ({
  type: GET_IP_RESPONSE,
  payload: response,
})

