import React from 'react'
import Review from 'Containers/Review'
import Error from 'Containers/Error'
import Success from 'Containers/Success'
import { PrivateRoute } from 'Utils/private-route'
import Loader from './BaseComponents/Loader'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import Invalid from 'Containers/Invalid'

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <PrivateRoute path="/error" component={Error} />
            <PrivateRoute path="/invalid" component={Invalid} />
            <PrivateRoute path="/success" component={Success} />
            <PrivateRoute path="/" component={Review} />
            <Redirect from="/" to="/error" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App
