import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { checkPin, saveReview, getIp } from 'Redux/Login/login.actions'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import { ThemeProvider } from '@material-ui/styles'
import Loader from 'BaseComponents/Loader'
import './style.css'

const authTheme = createTheme({
  palette: {
    primary: {
      main: '#088B8C',
    },
    secondary: {
      main: '#5F6064',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    // '@media(min-width: 960px)': {
    //   display: 'flex',
    //   justifyContent: 'center',
    //   height: '100vh',
    //   width: '100%',
    // },
  },
  header: {
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #e0e0e0',
  },
  header__bar: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'flex-start',
    height: '100%',
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  logo: {
    width: '180px',
  },
  clinic: {
    margin: '0.5rem 1rem 0.2rem',
    color: '#1A1F36',
    fontWeight: '600',
    fontSize: '18px',
    width: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis !important',
  },
  containerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 200px)': {
      display: 'block',
      height: 'fit-content',
    },

    // '@media(min-width: 760px)': {
    //   height: '75%',
    //   width: '350px',
    // },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '2rem',
    '@media(min-width: 760px)': {
      height: '75%',
      width: '400px',
    },
  },
  name: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    margin: '0.5rem 0 0.2rem',
    color: '#1A1F36',
    fontWeight: '600',
    fontSize: '20px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  rate: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    margin: '0.5rem 0 0.5rem',
    color: '#1A1F36',
    fontWeight: '600',
    fontSize: '16px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  reviewHeading: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    margin: '1rem 0 0.5rem',
    color: '#1A1F36',
    fontWeight: '600',
    fontSize: '16px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  subHeading: {
    margin: '0 0 1rem',
    color: '#4F566B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  stars: {
    marginBottom: '1rem',
    '@media(min-width: 500px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  textBox: {
    width: '100%',
  },
  confirmBtn: {
    margin: '2rem 0 1rem',
    width: '100%',
  },
  copyright: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}))

function Review(props) {
  const classes = useStyles()
  let a = window.location.href.split('/').pop()
  const [details, setDetails] = useState({})
  const [value, setValue] = React.useState('')
  const [stars, setStars] = React.useState(0)
  const [loading, setloading] = React.useState(true)
  const CHARACTER_LIMIT = 250

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    if (a) {
      props.checkPin(a.toString())
    } else {
      window.location.href = '/invalid'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.LoginData &&
      props.LoginData.pinResponse &&
      props.LoginData.pinResponse.response
    ) {
      let data = props.LoginData.pinResponse.response.data
      setDetails(data)
      setloading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.LoginData.pinResponse])

  const handleSubmit = () => {
    if (stars) {
      setloading(true)
      props.saveReview({
        id: 0,
        clinicId: details.clinicId,
        patientId: details.patientId,
        providerId: details.providerId,
        appointmentId: details.appointmentId,
        rating: stars,
        feedback: value,
      })
    }
  }

  useEffect(() => {
    if (
      props.LoginData &&
      props.LoginData.reviewResponse &&
      props.LoginData.reviewResponse.response
    ) {
      let resp = props.LoginData.reviewResponse.response
      if (resp.data) {
        let place = props.LoginData.reviewResponse.response.data.placeId
        // if (place && details.plan < 2) {}
        if (place && stars > 3) {
          window.location.replace(
            `https://search.google.com/local/writereview?placeid=${place}`,
          )
        }
        // else if (place && details.plan >= 2 && stars > 3) {}
        // else if (place && stars > 3) {
        //   window.location.replace(
        //     `https://search.google.com/local/writereview?placeid=${place}`,
        //   )
        // }
        //  else if (details.plan >= 2 && stars <= 3) {}
        else if (stars <= 3) {
          window.location.href = '/success'
        }
      } else {
        if (resp.responseCode === 132) {
          window.location.href = '/error'
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.LoginData.reviewResponse])

  let render = loading ? (
    <Loader />
  ) : (
    <ThemeProvider theme={authTheme}>
      <div className={classes.root}>
        <header className={classes.header}>
          <div className={classes.header__bar}>
            <Typography className={classes.clinic}>
              {details && details.clinicName}
            </Typography>
          </div>
        </header>
        <div className={classes.containerWrapper}>
          <Grid container className={classes.container}>
            <Grid xs={12} sm={12} item>
              <Typography className={classes.name}>
                Hello {details && details.patientFirstName}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} item>
              <p className={classes.subHeading}>
                We value your feedback on your visit with Dr.{' '}
                {details && details.providerMiddleName
                  ? details.providerFirstName +
                    ' ' +
                    details.providerMiddleName +
                    ' ' +
                    details.providerLastName
                  : details
                  ? details.providerFirstName + ' ' + details.providerLastName
                  : 'Unknown'}
                .
              </p>
            </Grid>
            <Grid xs={12} sm={12} item>
              <Typography className={classes.rate}>Rate your visit</Typography>
            </Grid>
            <Grid xs={12} sm={12} item>
              <Rating
                className={classes.stars}
                size="large"
                value={stars}
                onChange={(event, newValue) => {
                  setStars(newValue)
                }}
              />
            </Grid>
            {stars > 0 && stars <= 3 ? (
              <>
                <Grid xs={12} sm={12} item>
                  <Typography className={classes.reviewHeading}>
                    Leave a review of your visit
                  </Typography>
                </Grid>
                <Grid xs={12} sm={12} item>
                  <form noValidate autoComplete="off">
                    <TextField
                      className={classes.textBox}
                      id="outlined-multiline-static"
                      multiline
                      rows={6}
                      placeholder="Type your review here"
                      value={value}
                      onChange={handleChange}
                      variant="outlined"
                      inputProps={{
                        maxlength: CHARACTER_LIMIT,
                      }}
                      helperText={`${value.length}/${CHARACTER_LIMIT}`}
                    />
                  </form>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid xs={12} sm={12} item>
              <Button
                className={classes.confirmBtn}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {stars === 0 ? 'Next' : stars > 3 ? 'Next' : 'Submit Review'}
              </Button>
            </Grid>
            {/* <Grid xs={12} sm={12} item>
              <input type="checkbox" id="remind" name="remind" />
              <label for="remind"> Remember this device for 30 days</label>
            </Grid> */}
          </Grid>
        </div>
        <div className={classes.copyright}>
          <Typography
            variant="caption"
            align="center"
            color="textSecondary"
            display="block"
          >
            Copyright &copy; {new Date().getFullYear()}-
            {new Date().getFullYear() + 1} trillium.health
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )

  return render
}

const mapStateToProps = (state) => ({
  LoginData: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  checkPin: (values) => dispatch(checkPin(values)),
  saveReview: (values) => dispatch(saveReview(values)),
  getIp: (values) => dispatch(getIp(values)),
})
Review.propTypes = {
  checkPin: PropTypes.func,
  saveReview: PropTypes.func,
  getIp: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Review)
