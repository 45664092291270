import { VERIFY_PIN_RESPONSE, SAVE_REVIEW_RESPONSE, GET_IP_RESPONSE } from './login.types'

const INITIAL_STATE = {
  pinResponse: {},
  reviewResponse: {},
  ipResponse: {},
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERIFY_PIN_RESPONSE: {
      return { ...state, ...{ pinResponse: action.payload } }
    }
    case SAVE_REVIEW_RESPONSE: {
      return { ...state, ...{ reviewResponse: action.payload } }
    }
    case GET_IP_RESPONSE: {
      return { ...state, ...{ ipResponse: action.payload } }
    }
    default:
      return state
  }
}

export default loginReducer
